import au from './au-init';
import { CustomElement } from 'aurelia';
import { Route } from '@aurelia/router';

import Home from './home';
import Gallery from './gallery';

import 'op-flex/op-flex.css';

const App = CustomElement.define({
  name: 'app',
  template: '<home></home>',// <au-viewport></au-viewport>
  dependencies: [ Home, Gallery ]
}, class {});

// Route.configure({
//   routes: [
//     {
//       path: ['', 'home'],
//       component: Home,
//       title: 'Home',
//     },
//     {
//       path: 'about',
//       component: Gallery,
//       title: 'About',
//     }
//   ]
// }, App);

au.app({component: App, host: document.getElementById('app')});
au.start();
