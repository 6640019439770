import { CustomElement } from 'aurelia';

import Gallery from './gallery';

import Template from './home.html';

export default CustomElement.define({
  name: 'home',
  template: Template,
  dependencies: [ Gallery ]
}, class {
  constructor() {
    this.page = 'home';

    this.startScreen = true;
    window.addEventListener('scroll', this.onScroll);
  }

  go = (to) => {
    this.page = to;
  }

  onScroll = () => {
    // window.scrollTo(0, 0);
    this.startScreen = false;

    document.body.style.overflow = 'hidden';
    window.removeEventListener('scroll', this.onScroll);

    setTimeout(() => {
      document.body.style.overflow = '';
      // window.removeEventListener('scroll', this.onScroll);
    }, 550);
  }
});
